import React, {useEffect} from "react";
import {getApi} from "@navvis/indoorviewer";
import {initialize} from "./modifier/initialize";
export default function Structureview() {

    useEffect(() => {
        //hook IndoorViewer to div
        document.getElementById('iv').appendChild(document.createElement('ivion'));

        //get auth token

            getIvUserToken().then(token=>{
                getApi("https://hpa-tnds.iv.navvis.com",{loginToken: token}).then((iv) => {
                    //insert modifier here

                    initialize(iv);
                    // placement(iv);
                })
            })
        })


    async function getIvUserToken() {
        const response = await fetch(`https://kk1tuo4h79.execute-api.eu-central-1.amazonaws.com/default/structureview-credentials`,
            {
                method: "GET",
            });
        if (response.status !== 200) {
            throw new Error(response.statusText);
        }
        return await response.text();
    }

    return (
        <div id="iv">
            {/*<Placer/>*/}
        </div>
    )
}
